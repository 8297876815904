@import "../imported-styles";
@import "../components/buttons";

.training-finished-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: 49vh;
  .text-wrapper{
      text-align: left;
      h2{
          font-size: 3em;
          font-weight: 700;
      }
      p{
          color: $gray;
      }
  }

}
