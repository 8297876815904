@import "../imported-styles";
@import "../components/buttons";

#is-this-you-info-box {
  display: flex;
  border: 1px solid $gray;
  border-radius: 5px;
  padding: 10px;
  .is-this-you-personal-info {
    margin-right: .8em;
    font-family: roboto, sans-serif;
  }
}

.which-are-you-button{
    display: flex;
    border: 1px solid $gray;
    border-radius: 5px;
    padding: .5em .2em .5em 1.3em;
    text-transform: uppercase;
    background-color: $white;
    color: $navy;


    cursor: pointer;
    @include transition(500ms);
    &:hover {
      @include transition(500ms);
      background-color: $navy;
      color: $white;
    }
  .is-this-you-personal-info {
    margin-right: .8em;
    //font-family: sans-serif;
    padding-top: .25em;
    padding-bottom: .25em;
  }
}

#which-are-you-buttons-wrapper{
    margin-top: -2em;
    padding-bottom: 2em;
    ul{
        list-style: none;
        padding: 0;
        li{
            padding-top: 1em;
        }
    }
}

.text-left{
    text-align: left;
}
