@import "../imported-styles";
@import "../components/buttons";

.trainee-list-heading-wrapper {
  margin: 5em 0 0;
  .training-text {
    color: $gray;
  }
}


.return-button{
    background: none;
    border: none;
    position: fixed;
    top: 1.25rem;
    left: 1rem;
    img{
        height: 2.5rem;
        &:hover{
            cursor: pointer;
        }
    }
}