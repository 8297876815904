@import "../imported-styles";
@import "../components/buttons";

.review-card{
  position: absolute;
  width: 80%;
  height: 65vh;
  max-width: 1000px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 5px 13px 10px 18px rgba(51, 51, 51, 0.1);
  @include mq(800px){
    width: 100%;
    height: 100%;
  }
  .container {
    width: 80%;
    height: 100%;
    margin: 3.125em auto;
    .white-panel-company-logo {
      // width: 250px;
      // height: auto;
      position: absolute;
      top: 25px;
      left: 25px;
      height: 3.5vh;
      width: auto;
    }
    .lang-menu-wrapper {
      // top: 50px;
      // right: 95px;
    }
  }
}
