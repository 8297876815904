@import "../imported-styles";
@import "../components/buttons";

// Styles used on Training Lobby, Trainee List (Start Training) pages
#trainee-list-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3em auto 1em auto;
  height: 140px;
  .trainee-list-item{
    width: calc(50% - 20px);
    padding: 10px;
    border: 1px solid $gray;
    border-radius: 5px;
    margin-bottom: .5em;
    position: relative;
    font-family: roboto, sans-serif;
    height: 2.75em;

    .trainee-status-icon {
      background-image: url('../../images/Delete-Trainee-Icon.png');
      background-size: cover;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 10px;
      top: 9px;
      opacity: 1;
      cursor: pointer;
      &.remove {
        background-image: url('../../images/Delete-Trainee-Icon.png');
        background-size: cover;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 10px;
        top: 9px;
        opacity: .7;
        cursor: pointer;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  .is-active{
      border-color: $gold;
      background-color: $gold;
      color: #ffffff;
  }
}

#trainee-list-button-wrapper{
  text-align: center;
  display: flex;
  justify-content: flex-end;

  .pagination-buttons{
    background-color: $white;
    border-color: $white;
    padding: unset;
    border: unset;
    min-width: unset;
    img {
      width: 30px;
      height: 30px;
      @include transition(500ms);
      &:hover {
        opacity: .7;
        @include transition(500ms);
      }
      &.previous {
        transform: rotate(180deg);
      }
    }
  }
  p {
    align-items: center;
    display: flex;
    margin: 0 10px;
  }
  .disabled{
      opacity: .2;
  }
}
.trainee-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
}
