@import "../imported-styles";
@import "../components/buttons";

@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}

@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}


.video-page-wrapper {
  //Left Side - Video
  .react-player {
    video {
      background: $black;
    }
  }

  .video-control-panel{
      position: absolute;
      display: inline-flex;
      justify-content: center;
      left: 20vw;
      bottom: 2vh;
      padding: 1.5vh;
      background-color: $navy;
      opacity: .5;
      width: 60vw;
      border-radius: 50px;
      progress{
          width: 40vw;
          margin-left: 2vw;
          margin-top: .85vh;
          background-color: $white;
          border-radius: 25px;

      }
      progress::-moz-progress-bar { background: $gold; border-radius: 25px;}
      progress::-webkit-progress-bar {border-radius: 25px;}
        progress::-webkit-progress-value { background: $white; border-radius: 25px; }
        progress { color: $gold; border-radius: 25px; }
  }
  .hidden{
      display: none;
  }
  .player-buttons{
      background: none;
      border: none;
      img{
          height: 3vh;
      }

  }
  .margin-left-spacer{
      margin-left: 1vw;
  }
  .time-elapsed-wrapper{
      width: 4.5em;
      margin-left: 2vw;
      // display: none;
  }
  .time-elapsed{
      font-size: 3vh;
      color: #ffffff;
  }

  //Right Side
//   .video-panel-right{
//     display: flex;
//     align-items: center;
//     width: 28vw;
//     height: 100vh;
//     position: absolute;
//     top: 0;
//     right: 0;
//     background: url('../../images/Topo-Line-Art.png'), $white;
//     background-size:cover;
//     background-position: left;
//     @include mq(1366px){
//       width: 100vw;
//     }
//     .inner-wrapper {
//       height: 80vh;
//       width: 80%;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       margin: 0 auto;
//       @include mq(1366px){
//         width: 50%;
//       }
//       @include mq(800px){
//         width: 90%;
//       }
//       .text-wrapper{
//         @include mq(800px){
//           display: flex;
//           flex-direction: column;
//         }
//         .ss-logo {
//           width: 80%;
//           height: auto;
//           @include mq(800px){
//             width: 30%;
//             margin: 0 auto;
//           }
//         }
//         p {
//           margin: 1.5em 0;
//           color: $gray;
//           font-size: 20px;
//           @include mq(800px){
//             margin: .5em 0;
//             text-align: center;
//           }
//         }
//       }
//       .button-wrapper {
//         display: flex;
//         flex-direction: column;
//         margin: 0 auto;
//         @include mq(1366px){
//           flex-direction: row;
//         }
//         .top-btn {
//           margin-bottom: 1.5em;
//           @include mq(1366px){
//             margin: 0 1.5em 0 0;
//           }
//         }
//       }
//       .company-logo {
//         width: 250px;
//         height: auto;
//         margin: 0 auto;
//       }
//     }
//   }
.video-panel-overlay{
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,.25);
    background-size:cover;
    background-position: left;
    z-index: 2;

    .inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 50%;
        margin: auto;
        text-align: center;
        .terv-logo {
            width: 20em;
            height: auto;
            margin-bottom: 3em;
        }
        .button-wrapper {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            gap: 15px;
        }

    }
  }

    .panel-enter{
        //@TODO: handle panel enter animation
    }
    .panel-exit{
        //@TODO: handle panel exit animation
    }


}
