@import '../imported-styles';

.button,
.button-blue,
.button-light,
.button-clear {
  padding: 0.625rem 1.875rem;
  border-radius: 5px;
  min-width: 12rem;
  cursor: pointer;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  @include transition(500ms);
  &:hover {
    @include transition(500ms);
  }
}
.button-blue {
  background-color: $indigo;
  color: $white;
  border: 2px solid $indigo;
  &:hover {
    background-color: $indigo_hover;
  }
}
.button-light {
  border: 2px solid $gray;
  background-color: none;
  color: $gray;
  &:hover {
    background-color: $gray;
    color: $white;
  }
}
.button-clear {
  background-color: rgba(0, 0, 0, 0);
  color: $white;
  border: 2px solid $white;
}

.inactive {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
