@import "../imported-styles";
@import "buttons";

form {
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: .8em;
    @include mq(800px){
      margin-bottom: .3em;
    }
    .half-width,
    .full-width {
      display: flex;
      flex-direction: column;
    }
    .half-width {
      width: calc(50% - 8px);
    }
    .full-width {
      width: 100%;
    }
    label {
      font-size: 18px;
      margin-bottom: .2em;
      font-family: roboto, sans-serif;
      color: #818181;
      @include mq(800px){
        font-size: 15px;
        line-height: 15px;
      }
    }
    input,
    select {
      padding: .25em;
      border-radius: 5px;
      border: 1px solid $gray;
      background-color: #F2F2F2;
      &:focus-visible{
        border: 1px solid $gold;
        outline: none;
      }
      &[type=text],
      &[type=email],
      &[type=phone],
      &[type=tel],
      &[type=password] {
        font-size: 18px;
        padding: 10px;
        font-family: roboto, sans-serif;
        color: $gray;
        @include mq(800px){
          font-size: 16px;
          padding: 6px 0 0 10px;
        }
      }
      &::placeholder{
          color: #DCDCDC;
      }
    }
    select {
      font-size: 18px;
      color: $gray;
      font-family: roboto, sans-serif;
      padding: 10px;
      @include mq(800px){
        font-size: 16px;
        padding: 6px 0 0 10px;
      }
    }

    .has-error{
        input, select{
            border: 1px solid red;
            outline: none;
        }
    }
  }
  .form-whos-training-bottom-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    @include mq(800px){
      margin-top: .5em;
    }
    button{

    }
  }
  .form-bottom-button-wrapper {
    display: flex;
    //justify-content: space-evenly;
    justify-content: flex-start;
    margin: 1em 0 .5em;
    button:nth-child(n+2){
        margin-left: 2em;
    }
    @include mq(800px){
      margin-top: .5em;
    }
  }
}


#forgot-password-form{
    .row{
        justify-content: center;
        //margin-top: 2em;
    }
}

// .auth-card{
// h2{
//     margin-bottom: 1em;
// }
//     a{
//         color: $gold;
//         text-decoration: none;
//     }
//     a:hover{
//         color: $gray;
//     }

//     .form-bottom-button-wrapper{
//         justify-content: center;
//         margin-bottom: 2em;
//         margin-top: 2em;
//     }
// }
.auth-card{
    text-align: left;
    position: absolute;
    width: 50vw;
    max-width: 400px;
    top: 50%;
    left: 50%;
    @include translate(-50%,-50%);
    @include mq(1366px){
        width: 90%;
    }
    @include mq(800px){
        @include translate(-50%,-45%);
    }
    .auth-logo{
        width: 100%;
    }
    .page-title{
        padding: 1.5em 0 .75em 0;
    }
    a{
        color: $gray;
        text-decoration: none;
        font-size: .75em;
        &:hover {
            color: $gold;
        }
    }
    h2{
        color: $white;
        margin-bottom: .4em;
    }
    form{
        .row{
            > div{
                width: 100%;
                margin: 0 auto .4em;
                text-align: left;
            }
label{
    color: $white;
    text-align: left;
}
            input, a, button{
                
                width: 100%;
            }
        }
    }
    .auth-response-text{
        color: $white;
        margin-bottom: 1.5em;
    }
    .error-message{
        color: $gold;
    }
    #forgot-password-form{
        .row{
            justify-content: center;
        }
    }
}

.auth-response-text{
    margin-bottom: 1.5em;
}
