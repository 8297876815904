@import "../imported-styles";
@import "../components/buttons";

/* width */
::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  background-color: #eee;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
