@import "../imported-styles";
@import "../components/buttons";

.signature-trainee-info {
  margin-top: 14vh;
  font-size: 25px;
  text-align: left;
  .trainee-short-name {
    font-weight: 700;
  }
  .help-text {
    color: $gray;
    font-size: 18px;
  }
}
#agreement-box{
    border: 1px solid $gray;
    border-radius: 5px;
    position: absolute;
    width: 80%;
    height: 25vh;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    margin-top: 1.5em;
    #scroll-box-body {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      overflow-y: scroll;
      margin: 1em;
      text-align: left;
      padding: 2em;
      .text-item{
        margin-bottom: 1em;
      }
      .text-underline{
        text-decoration: underline;
        margin-bottom: .5em;
      }
      .bold-item{
        font-weight: 700;
      }
      .checkbox-item {
        display: flex;
        align-items: flex-start; /* Aligns the checkbox and label vertically */
        input[type="checkbox"] {
            margin-top: 2px; // Adjust this value as needed to fine-tune the alignment
        }
        margin-bottom: .5em;
    }
    
    }
}
#signature-pad {
  border: 1px solid $gray;
  border-radius: 5px;
  position: absolute;
  width: 80%;
  height: 25vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1.5em;
  .signature-pad-body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  // #scroll-box-body {
  //   position: absolute;
  //   left: 0px;
  //   right: 0px;
  //   top: 0px;
  //   bottom: 0px;
  //   overflow-y: scroll;
  //   margin: 1em;
  //   text-align: left;
  //   padding: 2em;
  // }
}
.agreement-checkbox-wrapper {
  position: absolute;
  left: 122px;
  bottom: 80px;
  width: 380px;
  font-family: roboto, sans-serif;
  form {
    display: flex;
    input {
      position: absolute;
      top: 0;
      left: -22px;
    }
  }
}
.review-card-footer{
  position: absolute;
  bottom: 75px;
  right: 95px;
  .button-light {
    margin-right: .75em;
  }
}
