@import "../imported-styles";
@import "../components/buttons";

.card-centered {
  position: absolute;
  width: 90%;
  //max-width: 700px;
  top: 50%;
  left: 50%;
  @include translate(-50%,-50%);
  @include mq(1366px){
    width: 90%;
  }
  @include mq(800px){
    @include translate(-50%,-45%);
  }
  .whos-training-wrapper {
    margin-bottom: 2.5em;
    position: relative;
    @include mq(800px){
      margin-bottom: .5em;
    }
    .training-heading {
      font-size: 1.75em;
      text-align: left;
      color: $navy;
      font-weight: 700;
      letter-spacing: -1px;
      @include mq(800px){
        font-size: 1.15em;
      }
    }
    .training-text{
        text-align: left;
      color: $gray;
    }
    #site-training-expiration-wrapper {
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      p {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        &.bold {
          font-weight: 900;
        }
        &.red-text {
          color: $red;
        }
      }
    }
  }
}
