@import '../imported-styles';
@import '../components/buttons';
// Timing
$container-rotate-timing: 1250ms;
$rotate-timing: 5000ms;
$spinner-line-count: 4;
$spinner-left-spin: $rotate-timing / $spinner-line-count;
$spinner-right-spin: $rotate-timing / $spinner-line-count;

.countdown-timer-wrapper {
  position: relative;
  padding: 100px;
  .spinner {
    height: 130px;
    width: 130px;
    left: 20%;
    top: 13%;
    position: absolute;
    /* Spins external container */
    animation: container-rotate #{$container-rotate-timing} linear infinite;
    .spinner-line {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-color: $gold;
      animation: fill-unfill-rotate #{$rotate-timing} cubic-bezier(
            0.4,
            0,
            0.2,
            1
          ) infinite both,
        line-1-fade-in-out #{$rotate-timing} cubic-bezier(0.4, 0, 0.2, 1) infinite
          both;
      .spinner-line-cog {
        display: inline-block;
        position: relative;
        width: 50%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;
        .spinner-line-cog-inner {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 200%;
          box-sizing: border-box;
          height: 100%;
          //border: 7px solid $black;
          border: 7px solid $gold;
          // border: double 17px $black;
          // background-image: linear-gradient(white, white), radial-gradient(circle at top left, $white,#333);
          background-origin: border-box;
          background-clip: content-box, border-box;
          border-radius: 50%;
          border-bottom-color: transparent;
          animation: none;
          &.spinner-line-cog-inner-left {
            border-right-color: transparent;
            transform: rotate(129deg);
            animation: left-spin #{$spinner-left-spin} cubic-bezier(
                0.4,
                0,
                0.2,
                1
              ) infinite both;
          }
          &.spinner-line-cog-inner-right {
            left: -100%;
            border-left-color: transparent;
            transform: rotate(-129deg);
            animation: right-spin #{$spinner-right-spin} cubic-bezier(
                0.4,
                0,
                0.2,
                1
              ) infinite both;
          }
          &.spinner-line-cog-inner-center {
            width: 1000%;
            left: -450%;
          }
        }
      }
      .spinner-line-ticker {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 45%;
        width: 10%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;
      }
    }
  }
  .opacity {
    // width: 35px;
    // height: 45px;
    // background: white  ;
    // position: absolute;
    // &.box-1-left {
    //   top: 70px;
    //   left: 100px;
    // }
    // &.box-2-left {
    //   top: 25px;
    //   left: 100px;
    // }
    // &.box-1-right {
    //   top: 70px;
    //   right: 100px;
    // }
    // &.box-2-right {
    //   top: 25px;
    //   right: 100px;
    // }
  }
  .countdown {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 52%;
    top: 46%;
    // @include translate(-51%,-53%);
    @include translate(-50%, -50%);
    #countdown-timer {
      position: absolute;
      top: 50%;
      left: 50%;

      //@include translate(-29%,-50%);
      @include translate(-50%, -50%);
      z-index: 1;
      // letter-spacing: -4px;
      font-size: 35px;
      // color: $white;
      color: $gold;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      left: 50%;
      top: 50%;
      // @include translate(-51%,-40%);
      @include translate(-50%, -50%);
      position: absolute;
    }
  }
}

/*-----------------------------------*\
    Animations
\*------------------------------------*/
@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
@keyframes left-spin {
  0% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@keyframes right-spin {
  0% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
@keyframes line-1-fade-in-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

// loader.scss
$gold: #b4984e; // Your brand gold color
$dark-blue: #1e293b; // A professional dark background
$loader-size: 80px;
$border-width: 4px;

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($dark-blue, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.inner-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-center {
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation: pulse 2s infinite ease-in-out;
  }
}

.spinner-wrapper {
  position: absolute;
  width: $loader-size * 2;
  height: $loader-size * 2;
}

.spinner {
  position: relative;
  width: 100%;
  height: 100%;
  animation: rotate 2s infinite linear;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: $border-width solid transparent;
    border-radius: 50%;
  }

  &::before {
    top: -$border-width;
    left: -$border-width;
    right: -$border-width;
    bottom: -$border-width;
    border-top-color: $gold;
    animation: spin 3s infinite ease;
  }

  &::after {
    top: $border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    border-bottom-color: rgba($gold, 0.5);
    animation: spin 1.5s infinite ease;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.8;
  }
}
