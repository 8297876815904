/*------------------------------------*\
    Fonts
\*------------------------------------*/

/* This is used for headers and subheaders */
@font-face {
  font-family: avenir-next;
  src: url(../fonts/Avenir-Next-LT-Pro-Bold.otf);
}
/* This is used for body text */
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:wght@300;400;500;700;900&display=swap');


/* This is used for body highlights and body text */
@font-face {
  font-family: arial-black;
  src: url(../fonts/Arial-Black.ttf);
}
html{
  font-family:avenir-next;
  /*
  Use below once frontend is all worked out to prevent movement
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  */
}

/*------------------------------------*\
    Font Sizes
\*------------------------------------*/
h1 {
  font-size: 2.2em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.25em;
}
p {
  font-size: 1em;
  font-family: roboto;
}

/*------------------------------------*\
    Mixins
\*------------------------------------*/
// Translate. Pass in x and y value for positioning
@mixin translate($x, $y) {
  -moz-transform: translateX($x) translateY($y);
  -webkit-transform: translateX($x) translateY($y);
  -o-transform: translateX($x) translateY($y);
  -ms-transform: translateX($x) translateY($y);
  transform: translateX($x) translateY($y);
}
//Transitions. Pass in animation duration
@mixin transition($duration) {
  -webkit-transition: all $duration cubic-bezier(0.455, 0.030, 0.000, 1.000);
  -moz-transition: all $duration cubic-bezier(0.455, 0.030, 0.000, 1.000);
  -o-transition: all $duration cubic-bezier(0.455, 0.030, 0.000, 1.000);
  transition: all $duration cubic-bezier(0.455, 0.030, 0.000, 1.000);
}
// Media Query
@mixin mq($width) {
  @media only screen and (max-width:$width) {
    @content;
  }
}

/*------------------------------------*\
    Colors
\*------------------------------------*/
// $navy: #1E2B3C;
// $gold: #F1AD2E;
$navy: #15202E;
$gold: #F39C1A;
$gray: #A0A1A5;
$white: #FFFFFF;
$black: #000000;

$white: #FFFFFF;
$red: #C95E5E; // for errors
$midnight: #15202E;
$gold: #F0AD2E;
$indigo: #6D90BF;
$indigo_hover: rgba(109, 144, 191, .8);
$darksea: #363F4B;
$gray: #AAAAAA;
$silver: #DDDDDD;
$offwhite: #F5F5F5;
$nobel: #989898; // color for form labels but using gray instead for now
$drawer_gray: #303741;

/*------------------------------------*\
    Reset
\*------------------------------------*/
body {
 margin: 0;
 overflow: hidden;
}
* {
  margin: unset;
}
