@import 'imported-styles';
@import 'components/buttons';

.bg-topo-dark {
  background: url('../images/Topo-Line-Art.png') no-repeat center center fixed,
    $navy;
  background-size: cover;
}
.bg-topo-light {
  background: url('../images/Topo-Line-Art.png') no-repeat center center fixed,
    $white;
  background-size: cover;
}
.bg-white {
  background-color: white;
}
.bg-none {
  background-color: unset;
}
.fullscreen {
  min-height: 100vh;
  min-width: 100vw;
}
.fullscreen-centered {
	display: flex;          
    justify-content: center;  
    align-items: center; 
	min-height: 100vh;
	min-width: 100vw;
  }
.text-navy {
  color: $navy;
}
.text-white {
  color: $white;
}

/*------------------------------------*\
  Global Styles
\*------------------------------------*/

/* LEFT SIDE */
.left-panel {
  width: 33vw;
  height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  @include mq(1024px) {
    width: 33vw;
  }
  @include mq(800px) {
    width: 35vw;
  }
  .yellow-popout {
    width: 45vw;
    height: 75vh;
    position: absolute;
    top: 45%;
    left: 22vw;
    @include translate(-50%, -50%);
    @include mq(1024px) {
      width: 40vw;
      height: 50vw;
      top: 34%;
      left: 19vw;
    }
    @include mq(800px) {
      width: 36vw;
      height: 34vw;
      top: 43%;
      left: 17vw;
    }
  }
  .yellow-popout-logo {
    position: absolute;
    top: 22vh;
    left: 5vw;
    width: 25vw;
    z-index: 2;
    @include mq(1024px) {
      top: 15vh;
      left: 6vw;
    }
    @include mq(800px) {
      top: 12vh;
      left: 6vw;
      width: 25vw;
    }
  }
  .text-wrapper {
    position: absolute;
    bottom: 4vh;
    left: 4vw;
    text-align: left;
    // width: 550px;
    @include mq(1250px) {
      width: auto;
      padding-right: 2em;
    }
    @include mq(1024px) {
      bottom: 10vh;
      left: 3vw;
    }
    @include mq(800px) {
      bottom: 1vh;
      left: 2vw;
    }
    .welcome-heading {
      color: white;
      font-size: 1.25em;
      font-weight: 800;
      margin-bottom: 1em;
      // width: 350px;
      @include mq(1024px) {
        font-size: 1.35em;
        width: 300px;
      }
      @include mq(800px) {
        width: unset;
        font-size: 1em;
      }
      .trademark {
        font-size: 10px;
        // position: absolute;
        // top: 0;
        vertical-align: super;
      }
    }
    // .welcome-company-logo-white{
    //   height: 2.2vh;
    //   width: auto;
    //   margin: 5.125em 0 1em;
    //   @include mq(1024px){
    //     height: 2vh;
    //   }
    //   @include mq(800px){
    //     margin: 1em 0 .25em;
    //     height: 2.5vh;
    //   }
    // }
    .welcome-text {
      font-family: roboto, sans-serif;
      color: $gray;
      font-weight: 400;
      @include mq(800px) {
        font-size: 0.9em;
      }
    }
    .link-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 1.5em;
      a {
        color: $gray;
        font-weight: 400;
      }
    }
  }
}

/* RIGHT SIDE */
.right-panel {
  width: 67vw;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  @include mq(1024px) {
    width: 60vw;
  }
  @include mq(800px) {
    width: 65vw;
  }
  .white-panel-company-logo {
    position: absolute;
    top: 3vh;
    left: 10%;
    height: 4vh;
    width: auto;
    @include mq(1366px) {
      left: 5%;
    }
    @include mq(800px) {
      height: 3vh;
      top: 5vh;
    }
  }
}

/* Language Selection Menu */
.lang-menu-wrapper {
  position: absolute;
  top: 25px;
  right: 25px;
  @include mq(800px) {
    top: 4vh;
  }

  .language-selector-btn {
    border: 0;
    background: none;
    text-decoration: none;
    text-transform: uppercase;
    color: $gray;
    cursor: pointer;
    .language-selector {
      color: $gray;
    }
    .language-image {
      width: 1.5em;
      margin-bottom: -0.35em;
    }
  }
  .language-options-wrapper {
    position: absolute;
    background: white;
    right: 0;
    z-index: 3;
    border: 2px solid $gray;
    padding: 0 1em;
    padding-top: 0.5em;
    border-radius: 10px;
    .rounded-md {
      background: none;
    }
    a {
      font-family: roboto, sans-serif;
      // background: $gold;
      // border: 2px solid $gold;
      // border-radius: 25px;
      // color: $white;
      display: block;
      color: $gray;
      padding-top: 0.5em;
      //width: 111px;
      min-width: 10em;
      // border-bottom: 1px solid $gray;
      text-transform: uppercase;
      margin-bottom: 0.5em;
      @include transition(500ms);
      cursor: pointer;
      &:hover {
        background: $white;
        color: $gold;
        @include transition(500ms);
      }
    }
  }
}

label {
  text-align: left;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  position: relative;
  background: white;
  padding: 3em 2em 2em;
  max-height: 80vh;
  max-width: 1200px;
  //overflow-y: scroll;
  //max-width: 90%;
  border-radius: 6px;
  border: 2px solid $gold;
  box-shadow: 5px 13px 10px 18px rgba(51, 51, 51, 0.1);
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1.3rem;
    .delete-icon {
      width: 1em;
      &:hover {
        opacity: 0.8;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  .button-blue {
    margin-top: 1em;
  }
}

.loading-svg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

// .location-selection {
//   ul {
//     list-style-type: none;
//     padding: 0;
//     margin: 0;

//     display: flex;
//     flex-direction: column;
//     li {
//       margin-top: 1rem;
//       button {
//         flex: 1;
//         width: 100%;
//       }
//     }
//   }
// }
.location-selection{
	text-align: center;
	max-width: 900px;
	
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex; 
        flex-direction: column; 
        li{
            margin-top: 1rem;
            button {
                flex: 1;
                width: 100%;
            }
        }
    }
    
}

body:has(.body-decorator) {
  @import '~@aws-amplify/ui-react/styles.css';

  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../images/Topo-Line-Art.png') no-repeat center center fixed,
    #15202e;
  background-size: cover;

  // Add the image at the top
  form[data-amplify-form] {
    &::before {
      content: '';
      display: block;
      width: 400px;
      height: 100px;
      background-image: url('../images/tervuren-logo-color-lg.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto 1.5rem;
    }
  }
}

.amplify-button--primary {
  background-color: $gold !important;
  border: 2px solid $gold !important;
  color: $midnight !important;
}
.amplify-button--small {
  color: $gold !important;
  color: $midnight !important;

}

